import { styled } from 'styled-components';
import type { ILinkData } from './models';
import { LinksBlock } from './LinksBlock';

const CurriculumLinksData: ILinkData[] = [
  { text: 'Pre-Kindergarten', url: '/math/pre-kindergarten' },
  { text: 'Kindergarten', url: '/math/kindergarten' },
  { text: '1st Grade', url: '/math/grade1' },
  { text: '2nd Grade', url: '/math/grade2' },
  { text: '3rd Grade', url: '/math/grade3' },
  { text: '4th Grade', url: '/math/grade4' },
  { text: '5th Grade', url: '/math/grade5' },
];

const CurriculumLinksBlock = styled(LinksBlock)`
  grid-row: 1;
  grid-column: 9 / span 2;

  ${({ theme: { down } }) => down('tabletMd')} {
    grid-column: 7 / span 2;
    grid-row: 1 / 3;
  }

  ${({ theme: { down } }) => down('tabletSm')} {
    grid-row: 3;
    grid-column: 1/ -1;

    & > div > a {
      line-height: 30px;
    }
  }

  ${({ theme: { down } }) => down('phone')} {
    grid-column: 1/-1;
  }
`;

const CurriculumLinks = ({ ...rest }) => {
  return (
    <CurriculumLinksBlock
      links={CurriculumLinksData}
      title="Curriculum"
      {...rest}
    />
  );
};

export { CurriculumLinks };
