import { styled } from 'styled-components';
import type { IBannerProps } from './models';
import type { StyledTransient } from '@hn-ui/themes';

const Container = styled.div<StyledTransient<Pick<IBannerProps, 'images'>>>`
  display: flex;
  justify-content: center;
  background: ${({ $images }) => $images['1920'].bg};
  a {
    width: 100%;
  }
  a > picture {
    display: flex;
    justify-content: center;
  }
  a > picture > img {
    width: 100%;
    height: 118px;
  }

  @media (min-width: 1920px) {
    background: ${({ $images }) => $images['1440'].bg};
  }

  @media (max-width: 1440px) {
    background: ${({ $images }) => $images['1280'].bg};
  }

  @media (max-width: 1280px) {
    background: ${({ $images }) => $images['1024'].bg};
  }

  @media (max-width: 1024px) {
    background: ${({ $images }) => $images['768'].bg};
  }

  @media (max-width: 768px) {
    background: ${({ $images }) => $images['640'].bg};
  }

  @media (max-width: 640px) {
    background: ${({ $images }) => $images['560'].bg};
  }

  @media (max-width: 560px) {
    a > picture > img {
      height: 150px;
    }
    background: ${({ $images }) => $images['375'].bg};
  }

  @media (max-width: 375px) {
    background: ${({ $images }) => $images['320'].bg};
  }
`;

const Banner = ({ url, images }: Omit<IBannerProps, 'authUrl'>) => {
  return (
    <Container $images={images}>
      <a href={url} rel="noreferrer" aria-label="banner">
        <picture>
          <source media="(max-width:375px)" srcSet={images['320'].url} />
          <source media="(max-width:560px)" srcSet={images['375'].url} />
          <source media="(max-width:768px)" srcSet={images['640'].url} />
          <source media="(max-width:1024px)" srcSet={images['768'].url} />
          <source media="(max-width:1280px)" srcSet={images['1024'].url} />
          <source media="(max-width:1440px)" srcSet={images['1280'].url} />
          <source media="(max-width:1920px)" srcSet={images['1440'].url} />
          <img
            src={images['1920'].url}
            alt="banner"
            loading="eager"
            fetchPriority="high"
          />
        </picture>
      </a>
    </Container>
  );
};

export { Banner };
