import { BlueLink } from '@hn-ui/links';
import { styled } from 'styled-components';

const MenuItemBase = styled(BlueLink)`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black10};
  line-height: 24px;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
`;

export const MenuItem = styled(MenuItemBase)`
  &:hover {
    color: ${({ theme }) => theme.colors.blue50};
  }
`;

export const SetupClassButton = styled(MenuItemBase)`
  height: 40px;
  padding: 8px 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.blue60};
  color: ${({ theme }) => theme.colors.black100};
  font-size: 14px;
  font-weight: 700;

  &:hover {
    background: ${({ theme }) => theme.colors.blue50};
    color: ${({ theme }) => theme.colors.black100};
  }

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 12px;
    line-height: 16px;
  }
`;
