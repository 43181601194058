import { styled } from 'styled-components';
import { BlueLink } from '@hn-ui/links';
import { support } from '@hn-front/data-types';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.modal};
  position: absolute;
  padding: 16px 24px;
  gap: 8px;
  color: ${({ theme }) => theme.colors.black10};
  bottom: 0px;
  left: 0px;
  transform: translateY(100%);
  border-top: 1px solid rgb(215, 221, 228);
  box-shadow: rgba(0, 0, 0, 0.07) 0px 8px 20px 0px;
  background: ${({ theme }) => theme.colors.black100};
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 14px;
    line-height: 22px;
  }
`;

const Title = styled.h3`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 14px;
    line-height: 22px;
  }
`;

const ScBlueLink = styled(BlueLink)`
  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 14px;
    line-height: 22px;
  }
`;

const AdminsInfo = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <Title>Want to sync your school/district? We can help you!</Title>
      <Text>
        Automated sync via Clever, ClassLink or manual rosters available.
        Contact us at{' '}
        <ScBlueLink href={`mailto:${support.email}`}>
          {support.email}
        </ScBlueLink>{' '}
        to get started
      </Text>
    </Container>
  );
};

export { AdminsInfo };
