import { useCallback, useRef } from 'react';
import { styled } from 'styled-components';
import { HappyLogo } from '../../HappyLogo';
import { HeaderContainer, HeaderOuterContainer } from '../Common';
import { useOutside, useToggle } from '@hn-front/utils';
import { ParentsInfo } from './ParentsInfo';
import { AdminsInfo } from './AdminsInfo';
import { MainBurger } from './MainBurger';
import { MenuItem, SetupClassButton } from './Common';
import type { IUserInfo } from 'app/models/auth';
import { GO_URL, teacherAuthUrls } from 'app/utils/urls.data';

const MenuItems = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  ${({ theme: { down } }) => down('tabletMd')} {
    display: none;
  }
`;

const Burger = styled(MainBurger)`
  display: none;
  ${({ theme: { down } }) => down('tabletMd')} {
    display: flex;
  }
`;

const MainHeader = ({
  userType,
  id,
  userEmail,
  userName,
  ...rest
}: IUserInfo) => {
  const { isToggled: isParents, toggle: toggleParents } = useToggle();
  const { isToggled: isAdmins, toggle: toggleAdmins } = useToggle();

  const ref = useRef(null);

  useOutside(ref, (event) => {
    if ((event?.target as HTMLAnchorElement).innerText === 'For Parents')
      return;
    if ((event?.target as HTMLAnchorElement).innerText === 'For Admins') return;
    if (isParents) toggleParents();
    if (isAdmins) toggleAdmins();
  });

  const handleOnToggleParents = useCallback(() => {
    if (isAdmins) toggleAdmins();
    toggleParents();
  }, [toggleAdmins, toggleParents, isAdmins]);

  const handleOnToggleAdmins = useCallback(() => {
    if (isParents) toggleParents();
    toggleAdmins();
  }, [toggleAdmins, toggleParents, isParents]);

  const isSignedIn = Boolean(id);

  return (
    <HeaderOuterContainer>
      <HeaderContainer {...rest}>
        <HappyLogo />
        <div>
          <MenuItems>
            <MenuItem as="button" href="#" onClick={handleOnToggleParents}>
              For Parents
            </MenuItem>
            <MenuItem as="button" href="#" onClick={handleOnToggleAdmins}>
              For Admins
            </MenuItem>
            <MenuItem as="button" href="#" className="open-intercom-messenger">
              Contact us
            </MenuItem>
            <MenuItem href="/math">Curriculum</MenuItem>
            <MenuItem href="/pricing">Pricing</MenuItem>
            {isSignedIn ? (
              <SetupClassButton href={GO_URL}>
                Go to my account
              </SetupClassButton>
            ) : (
              <SetupClassButton href={teacherAuthUrls.signup}>
                Set up your class
              </SetupClassButton>
            )}
          </MenuItems>
          <div ref={ref}>
            {isParents && <ParentsInfo />}
            {isAdmins && <AdminsInfo />}
          </div>
          <Burger id={id} userEmail={userEmail} userName={userName} />
        </div>
      </HeaderContainer>
    </HeaderOuterContainer>
  );
};

export { MainHeader };
