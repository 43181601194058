import { styled } from 'styled-components';
import { BlueLink } from '@hn-ui/links';
import { cs } from '@hn-front/data-types';
import { teacherAuthUrls } from 'app/utils/urls.data';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.modal};
  position: absolute;
  padding: 16px 24px;
  gap: 8px;
  color: ${({ theme }) => theme.colors.black10};
  bottom: 0px;
  left: 0px;
  transform: translateY(100%);
  border-top: 1px solid rgb(215, 221, 228);
  box-shadow: rgba(0, 0, 0, 0.07) 0px 8px 20px 0px;
  background: ${({ theme }) => theme.colors.black100};
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 14px;
    line-height: 22px;
  }
`;

const Title = styled.h3`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 14px;
    line-height: 22px;
  }
`;

const ScBlueLink = styled(BlueLink)`
  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 14px;
    line-height: 22px;
  }
`;

const ParentsInfo = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <Title>Kids use Happy Numbers at school?</Title>
      <Text>
        <b>Yes</b> — ask their teacher to share login information to preserve
        their progress within the curriculum.
      </Text>
      <Text>
        <b>No</b> — check out an article{' '}
        <ScBlueLink target="_blank" href={`mailto:${cs.email}`}>
          How can parents get a class code?
        </ScBlueLink>
      </Text>
      <Text>
        <b>Homeschooling?</b> —{' '}
        <ScBlueLink
          href={`${teacherAuthUrls.signup}?utm_source=menu_homeschooling&utm_medium=menu_homeschooling`}
        >
          Sign up here
        </ScBlueLink>
      </Text>
    </Container>
  );
};

export { ParentsInfo };
