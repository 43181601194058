import { HappyLogo } from 'app/components/HappyLogo';
import { styled } from 'styled-components';
import { SocialLinks } from '../SocialLinks';
import { RightsText } from '../Common';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  grid-column: 1 / span 6;

  ${({ theme: { down } }) => down('tabletSm')} {
    grid-column: 1 / -1;
  }
`;

const AddressText = styled(RightsText)`
  color: ${({ theme }) => theme.colors.black60};
  margin-bottom: 40px;

  ${({ theme: { down } }) => down('tabletSm')} {
    br {
      display: none;
    }
  }
`;

const CompanyInfo = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <HappyLogo />
      <RightsText>© 2025 Happy Numbers. All rights reserved</RightsText>
      <AddressText>
        Happy Numbers Inc., 2261 Market Street STE 22178, <br />
        San Francisco, CA 94114, USA
      </AddressText>
      <SocialLinks />
    </Container>
  );
};

export { CompanyInfo };
