import { styled } from 'styled-components';
import { PolicyLink, PolicyText } from './Common';
import { publicUrls } from 'app/utils/urls.data';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 56px;
  grid-column: 1 / -1;
  grid-row: 2;

  ${({ theme: { down } }) => down('tabletMd')} {
    margin-top: 56px;
    grid-row: 3;
  }

  ${({ theme: { down } }) => down('tabletSm')} {
    grid-row: 5;
    margin-top: 0;
  }

  ${({ theme: { down } }) => down('phone')} {
    gap: 40px;
  }
`;

const Policy = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <PolicyText>
        Use of this web site constitutes acceptance of the{' '}
        <PolicyLink href={publicUrls.privacyPolicy}>Privacy Policy</PolicyLink>{' '}
        and{' '}
        <PolicyLink href={publicUrls.termsOfService}>
          Terms of Service
        </PolicyLink>
        . Happy Numbers complies with the Children's Online Privacy Protection
        Act (COPPA), the Family Educational Rights and Privacy Act (FERPA), and
        the California Education Code including the Student Online Personal
        Information Protection Act (SOPIPA) and AB1584.
      </PolicyText>
    </Container>
  );
};

export { Policy };
