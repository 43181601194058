import { styled } from 'styled-components';
import type { ILinkData } from './models';
import { LinksBlock } from './LinksBlock';
import { buildFullPath } from 'app/utils';
import type { StyledTransient } from '@hn-ui/themes';
import { Link } from './Common';

interface ISupportLinks {
  isAuth?: boolean;
}

const SupportLinksData: ILinkData[] = [
  { text: 'Help Center', url: 'https://help.happynumbers.com/en/' },
  { text: 'Contact us', url: '#', className: 'open-intercom-messenger' },
  { text: 'Security', url: buildFullPath('/public/security') },
  {
    text: 'Vulnerability Disclosure Program',
    url: buildFullPath('/public/vdp'),
  },
];

const SupportLinksBlock = styled(LinksBlock)<StyledTransient<ISupportLinks>>`
  grid-column: 11 / span 2;

  ${({ theme: { down } }) => down('tabletMd')} {
    grid-column: 10;
    grid-row: ${({ $isAuth }) => ($isAuth ? 1 : 2)};
  }

  ${({ theme: { down } }) => down('tabletSm')} {
    grid-row: 4;
    grid-column: 1/-1;
    margin-bottom: 40px;
    transform: none;
  }

  ${Link} {
    white-space: normal;
  }
`;

const SupportLinks = ({ isAuth, ...rest }: ISupportLinks) => {
  return (
    <SupportLinksBlock
      links={SupportLinksData}
      title="Support"
      $isAuth={isAuth}
      {...rest}
    />
  );
};

export { SupportLinks };
