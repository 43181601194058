import { styled } from 'styled-components';
import { LinksBlock } from './LinksBlock';
import type { ILinkData } from './models';
import { publicUrls } from 'app/utils/urls.data';

const CompanyLinksData: ILinkData[] = [
  {
    text: 'Ambassador',
    url: '/ambassador',
    target: '_blank',
    requireAuth: true,
  },
  { text: 'Blog', url: '/blog' },
  { text: 'Pricing', url: '/pricing' },
  { text: 'Privacy Policy', url: publicUrls.privacyPolicy },
  {
    text: 'Terms of Service',
    url: publicUrls.termsOfService,
  },
];

const CompanyLinksBlock = styled(LinksBlock)<{ $isAuth?: boolean }>`
  grid-row: 1;
  grid-column: ${({ $isAuth }) => ($isAuth ? '9 / span 2' : '7 / span 2')};

  ${({ theme: { down } }) => down('tabletMd')} {
    grid-row: 1;
    grid-column: ${({ $isAuth }) => ($isAuth ? '8 / span 2' : '10 / span 2')};
  }
  ${({ theme: { down } }) => down('tabletSm')} {
    grid-row: 2;
    grid-column: 1/-1;
    margin-top: 32px;
    & > div > a {
      line-height: 30px;
    }
  }
`;

interface ICompanyLinksProps {
  isAuth?: boolean;
}

const CompanyLinks = ({ isAuth, ...rest }: ICompanyLinksProps) => {
  const links = CompanyLinksData.filter(
    (link) => isAuth || link.text !== 'Ambassador',
  );

  return (
    <CompanyLinksBlock
      $isAuth={isAuth}
      links={links}
      title="Company"
      {...rest}
    />
  );
};

export { CompanyLinks };
