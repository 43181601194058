import { Banner } from './Banner';
import { earlyBirdConfig } from './config';

interface IBannerSliderProps {
  isAuth?: boolean;
}

const BannerSlider = ({ isAuth }: IBannerSliderProps) => {
  const url = isAuth ? earlyBirdConfig.authUrl : earlyBirdConfig.url;
  return (
    <div role="slider" aria-valuenow={0} aria-label="banner-slider">
      <Banner images={earlyBirdConfig.images} url={url} />
    </div>
  );
};

export { BannerSlider };
