import { Link } from './Common';
import type { ILinkData } from './models';
import { styled } from 'styled-components';

export interface ILinksBlockProps {
  title: string;
  links: ILinkData[];
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;

  ${({ theme: { down } }) => down('tabletSm')} {
    margin-bottom: 24px;
  }
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: ${({ theme }) => theme.colors.black10};

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 14px;
    line-height: 24px;
  }
`;

const ScLink = styled(Link)`
  white-space: nowrap;
  ${({ theme: { down } }) => down('tabletSm')} {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;

  ${({ theme: { down } }) => down('tabletSm')} {
    flex-flow: row;
    align-items: center;
    flex-wrap: wrap;
  }
`;

const LinksBlock = ({ title, links, ...rest }: ILinksBlockProps) => {
  return (
    <Container {...rest}>
      <Title>{title}</Title>
      <LinksContainer>
        {links.map(({ text, url, target = '_self', className }) => {
          return (
            <ScLink key={url} href={url} className={className} target={target}>
              {text}
            </ScLink>
          );
        })}
      </LinksContainer>
    </Container>
  );
};

export { LinksBlock };
