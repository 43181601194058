import type { IBannerProps } from './models';

export const evergreenConfig: IBannerProps = {
  images: {
    '320': {
      bg: '#100CCA',
      url: 'https://s3.us-west-1.amazonaws.com/happy-banners/Evergreen/320.svg',
    },
    '375': {
      bg: '#100CCA',
      url: 'https://s3.us-west-1.amazonaws.com/happy-banners/Evergreen/375.svg',
    },
    '560': {
      bg: '#100CCA',
      url: 'https://s3.us-west-1.amazonaws.com/happy-banners/Evergreen/560.svg',
    },
    '640': {
      bg: '#100CCA',
      url: 'https://s3.us-west-1.amazonaws.com/happy-banners/Evergreen/640.svg',
    },
    '768': {
      bg: '#100CCA',
      url: 'https://s3.us-west-1.amazonaws.com/happy-banners/Evergreen/768.svg',
    },
    '1024': {
      bg: '#100CCA',
      url: 'https://s3.us-west-1.amazonaws.com/happy-banners/Evergreen/1024.svg',
    },
    '1280': {
      bg: '#100CCA',
      url: 'https://s3.us-west-1.amazonaws.com/happy-banners/Evergreen/1280.svg',
    },
    '1440': {
      bg: '#100CCA',
      url: 'https://s3.us-west-1.amazonaws.com/happy-banners/Evergreen/1440.svg',
    },
    '1920': {
      bg: '#100CCA',
      url: 'https://s3.us-west-1.amazonaws.com/happy-banners/Evergreen/1440.svg',
    },
  },
  url: '/t/auth/signup',
  authUrl: '/t/auth/signup',
};

export const earlyBirdConfig: IBannerProps = {
  images: {
    '320': {
      bg: '#29C8A2',
      url: 'https://s3.us-west-1.amazonaws.com/happy-banners/EarlyBird/320.svg',
    },
    '375': {
      bg: '#29C8A2',
      url: 'https://s3.us-west-1.amazonaws.com/happy-banners/EarlyBird/375.svg',
    },
    '560': {
      bg: '#29C8A2',
      url: 'https://s3.us-west-1.amazonaws.com/happy-banners/EarlyBird/560.svg',
    },
    '640': {
      bg: '#29C8A2',
      url: 'https://s3.us-west-1.amazonaws.com/happy-banners/EarlyBird/640.svg',
    },
    '768': {
      bg: '#29C8A2',
      url: 'https://s3.us-west-1.amazonaws.com/happy-banners/EarlyBird/768.svg',
    },
    '1024': {
      bg: '#29C8A2',
      url: 'https://s3.us-west-1.amazonaws.com/happy-banners/EarlyBird/1024.svg',
    },
    '1280': {
      bg: '#29C8A2',
      url: 'https://s3.us-west-1.amazonaws.com/happy-banners/EarlyBird/1280.svg',
    },
    '1440': {
      bg: '#29C8A2',
      url: 'https://s3.us-west-1.amazonaws.com/happy-banners/EarlyBird/1440.svg',
    },
    '1920': {
      bg: '#29C8A2',
      url: 'https://s3.us-west-1.amazonaws.com/happy-banners/EarlyBird/1440.svg',
    },
  },
  url: '/pricing',
  authUrl: '/quote/school?reason=quote_request',
};
