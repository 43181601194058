import { BlueLink } from '@hn-ui/links';
import { styled } from 'styled-components';
import {
  Twitter,
  Linkedin,
  Facebook,
  Instagram,
  Pinterest,
  Youtube,
} from '@hn-ui/icons';

const SocialContainer = styled.div`
  display: flex;
  gap: 24px;

  ${({ theme: { down } }) => down('tabletMd')} {
    gap: 16px;
    & > a > svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const TwitterIcon = styled(Twitter)`
  & > g > path:first-child {
    fill: ${({ theme }) => theme.colors.black10};
  }
`;

const LinkedinIcon = styled(Linkedin)`
  & > path:first-child {
    fill: ${({ theme }) => theme.colors.black10};
  }
`;

const FacebookIcon = styled(Facebook)`
  & > path:first-child {
    fill: ${({ theme }) => theme.colors.black10};
  }
`;

const PinterestIcon = styled(Pinterest)`
  & > circle {
    fill: ${({ theme }) => theme.colors.black10};
  }
`;

const YoutubeIcon = styled(Youtube)`
  & > path:first-child {
    fill: ${({ theme }) => theme.colors.black10};
  }
`;
export const SocialLinks = ({ ...rest }) => (
  <SocialContainer {...rest}>
    <BlueLink
      target="_blank"
      href="https://www.youtube.com/c/HappyNumbers?sub_confirmation=1"
      aria-label="youtube"
    >
      <YoutubeIcon size={32} />
    </BlueLink>
    <BlueLink
      target="_blank"
      href="https://www.facebook.com/happynumberscom"
      aria-label="meta"
    >
      <FacebookIcon size={32} />
    </BlueLink>
    <BlueLink
      target="_blank"
      href="https://www.instagram.com/happynumbers/"
      aria-label="instagram"
    >
      <Instagram size={32} />
    </BlueLink>
    <BlueLink
      target="_blank"
      href="https://twitter.com/intent/user?screen_name=happynumbers"
      aria-label="twitter"
    >
      <TwitterIcon size={32} />
    </BlueLink>
    <BlueLink
      target="_blank"
      href="https://www.linkedin.com/company/happynumbers/"
      aria-label="linkedin"
    >
      <LinkedinIcon size={32} />
    </BlueLink>
    <BlueLink
      target="_blank"
      href="https://hu.pinterest.com/happynumbers/"
      aria-label="pinterest"
    >
      <PinterestIcon size={32} />
    </BlueLink>
  </SocialContainer>
);
