import { FooterContainer, FooterOuterContainer } from '../Common';
import { CompanyLinks } from '../CompanyLinks';
import { CurriculumLinks } from '../CurriculumLinks';
import { Policy } from '../Policy';
import { SupportLinks } from '../SupportLinks';
import { CompanyInfo } from './CompanyInfo';

interface IMainFooterProps {
  isAuth?: boolean;
}

const MainFooter = ({ isAuth, ...rest }: IMainFooterProps) => {
  return (
    <FooterOuterContainer>
      <FooterContainer {...rest}>
        <CompanyInfo />
        <CompanyLinks isAuth={isAuth} />
        {!isAuth && <CurriculumLinks />}
        <SupportLinks isAuth={isAuth} />
        <Policy />
      </FooterContainer>
    </FooterOuterContainer>
  );
};

export { MainFooter };
